import React from 'react';
import Box from '@material-ui/core/Box';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const AppBadges = ({ pb = 0, pt = 0, width }) => (
    <Box pb={pb} pt={pt} display="flex" flexDirection="row" justifyContent={isWidthUp('sm', width) ? "start" : "center"}>
        <Box display="inline-block" pr={2}>
            <a href="https://play.google.com/store/apps/details?id=com.DevTonics.SpeedyDock" target="_blank" rel="noreferrer noopener">
                <img
                    alt="Get SpeedyDock on Google Play"
                    height="40"
                    width="133"
                    src="/badges/google-play.png"
                />
            </a>
        </Box>
        <a href="https://apps.apple.com/us/app/speedydock/id1124648688" target="_blank" rel="noreferrer noopener">
            <img
                alt="Get SpeedyDock on App Store"
                height="40"
                width="120"
                src="/badges/app-store.svg"
            />
        </a>
    </Box>
);

export default withWidth()(AppBadges);