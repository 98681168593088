import React from 'react';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

import Addons from '../components/add-ons/Addons';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Integrations from '../components/integrations/Integrations';
import Screenshots from '../components/marketing/Screenshots';
import PaletteContext from '../context/PaletteContext';
import ProductPage from '../components/product-page/ProductPage';
import ProductPageFeature from '../components/product-page/ProductPageFeature';
import ProductPageFeatures from '../components/product-page/ProductPageFeatures';
import ProductPageIntro from '../components/product-page/ProductPageIntro';
import ProductPagePricing from '../components/product-page/ProductPagePricing';
import ProductPageSolutions from '../components/product-page/ProductPageSolutions';
import ProductPageTestimonials from '../components/product-page/ProductPageTestimonials';

const useStyles = makeStyles({
    productPageWrapper: {
        minHeight: '1500px'
    }
});

const Product = ({ pageContext: { addons, product } }) => {
    const classes = useStyles();
    return (
        <PaletteContext.Provider value={product.paletteColor}>
            <Layout>
                <SEO title={product.seo.title} description={product.seo.description} keywords={product.seo.keywords} />
                <ProductPage>
                    <Box color={`${product.paletteColor}.dark`} className={ classes.productPageWrapper }>
                        {
                            product.introSection && (
                                <ProductPageIntro
                                    description={product.introSection.description}
                                    title={product.name}
                                    paletteColor={product.paletteColor}
                                    photoSrc={product.introSection.photoSrc}
                                    photoAlt={product.introSection.photoAlt}
                                    appBadges={product.introSection.appBadges}
                                />
                            )
                        }
                        {
                            Array.isArray(product.solutionsSection) && (
                                <ProductPageSolutions solutions={product.solutionsSection} />
                            )
                        }
                        {
                            product.featuresSection && (
                                <ProductPageFeatures
                                    paletteColor={product.paletteColor}
                                    title={product.featuresSection.title}
                                >
                                    {
                                        product.featuresSection.features.map((feature, index) => (
                                            <ProductPageFeature
                                                description={feature.description}
                                                key={feature.name}
                                                name={feature.name}
                                                paletteColor={product.paletteColor}
                                                imgSrc={feature.imgSrc}
                                                angledBottomSection={index !== product.featuresSection.features.length - 1}
                                            />
                                        ))
                                    }
                                </ProductPageFeatures>
                            )
                        }
                        {
                            product.screenshotsSection && <Screenshots products={[product]} angledTopSection={product.featuresSection && product.featuresSection.features.length % 2 !== 1} paletteColor={product.paletteColor} />
                        }
                        {
                            product.integrationsSection && <Integrations product={product.name} />
                        }
                        {
                            product.addonsSection &&
                            <Addons
                                addons={addons}
                                paletteColor={product.paletteColor}
                                product={product.name}
                            />
                        }
                        {
                            product.pricingSection && (
                                <ProductPagePricing
                                    pricingSubtitle={product.pricingSection.subtitle}
                                    benefits={product.pricingSection.benefits}
                                    isFixedPrice={product.pricingSection.isFixedPrice}
                                    product={product.name}
                                    price={product.pricingSection.price}
                                    pricePer={product.pricingSection.pricePer}
                                />
                            )
                        }
                        {
                            product.testimonialsSection && <ProductPageTestimonials product={product.name} paletteColor={product.paletteColor} />
                        }
                    </Box>
                </ProductPage>
            </Layout>
        </PaletteContext.Provider>
    );
};

export default Product;
